<template>
  <div class="select-toy">
    <div class="select-toy-title"></div>
    <div class="select-toy-table">
      <ul class="th">
        <li>公司</li>
        <li>状态</li>
        <li>型号</li>
        <li>产品</li>
      </ul>
      <vue-seamless-scroll
        v-if="config.anim"
        ref="actualTimeToy"
        :data="selectToyData"
        :class-option="optionHover"
        class="select-toy-body"
      >
        <ul
          class="td"
          v-for="(item, i) in selectToyData"
          :key="i"
        >
          <li>{{item.company}}</li>
          <li>{{item.typeStr}}</li>
          <li>{{item.article_number}}</li>
          <li>{{item.name}}</li>
        </ul>
      </vue-seamless-scroll>
      <div
        v-else
        class="select-toy-body"
      >
        <ul
          class="td"
          v-for="(item, i) in selectToyDataOnePage"
          :key="i"
        >
          <li>{{item.company}}</li>
          <li>{{item.typeStr}}</li>
          <li>{{item.article_number}}</li>
          <li>{{item.name}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getToyRealtime } from "@/api/index.js";
import { useTimer } from "@/libs/timer.js";
import { useDataPage } from "@/libs/dataPage.js";
import config from "@/config/index.js";
export default {
  components: {

  },
  props: {
    
  },
  data() {
    return {
      config,
      optionHover: {
        step: 0.5, //数值阅大滚动越快
        limitMoveNum: 5,  //开始无缝滚动的数据量 data.length
        hoverStop: true, //鼠标悬停
        direction: 1, //0向下，1向上，2向左，3向右
        openWatch: true,    //开启数据实时监控刷新dom
        singleHeight: 0,    //单步运动停止的高度（默认0，无缝不停的滚动）
        singleWidth: 0, //单步运动停止的宽度（默认0，无缝不停的滚动）
        waitTime: 1000, //单步运动停止的时间（默认1000ms）
      },
      //选中的产品数据
      selectToyData: [],
      selectToyDataOnePage: [],
      timer: null,
      dataPage: null,
    };
  },
  computed: {

  },
  created(){
    if(!config.anim){ //没有动画用切换渲染数据
      this.timer = useTimer();
      this.dataPage = useDataPage(4);
      this.timer.addEvent();
    }
  },
  mounted() {
    
  },
  beforeDestroy() {
    if(!config.anim){
      //销毁定时器
      this.timer.destroy();
      this.timer.removeEvent();
    }
  },
  methods: {
    initData(refresh){
      let postData = {refresh: refresh};
      if(!config.anim) postData['pageSize'] = 50;
      getToyRealtime(postData).then(res => {
        if (res.code == 0) {
          let tmp = this.selectToyData;
          this.selectToyData = [];

          this.$nextTick(() => {
            if (refresh == 0) {
              this.selectToyData = res.data.data;
            } else {
              this.selectToyData = this.handleTimitArrayTotal(tmp, res.data.data);
            }
            if(!config.anim){
              this.dataPage.init(this.selectToyData); 
              this.timer.init(()=>{
                this.selectToyDataOnePage = this.dataPage.next();
              });
              this.selectToyDataOnePage = this.dataPage.next();
              this.timer.start();
            }
          })
        }
      })
    },
    //限制数组总条数
    handleTimitArrayTotal(oldData, newData, num = 1000) {
      //   let oldLen = oldData.length;
      //   let newLen = newData.length;
      //   let len = oldLen + newLen;
      //   let result = [];
      //   if (newLen >= num) {    //新的已经超过或等于总条数直接全用新的
      //     result = newData;
      //   } else if (len > num) {    //新旧大于总条数，截取掉旧的后面部分，把新的填充在前面
      //     result = [...newData, ...oldData.slice(0, oldLen - (len - num) - 1)];
      //   } else {    //其他，直接两个合并
      //     result = [...newData, ...oldData];
      //   }
      let result = [...newData, ...oldData].slice(0, num);
      return result;
    },
  }
};
</script>
<style lang="scss" scoped>
.select-toy {
  width: 532px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .select-toy-title {
    flex-shrink: 0;
    height: 37px;
    background: url(~@/assets/images/select_toy_title.png) no-repeat;
    background-size: 100% 100%;
  }
  .select-toy-table {
    flex: 1;
    overflow: hidden;
    background: url(~@/assets/images/bottom_1.png)
      rgba(18, 42, 62, 0.4) no-repeat center bottom;
    border: 0.5px solid rgba(94, 204, 255, 0.4);
    background-size: 534px 12px;
    .select-toy-body {
      overflow: hidden;
      height: 100%;
    }
    .th,
    .td {
      background: url(~@/assets/images/line_1.png) no-repeat center
        bottom;
      li {
        &:nth-child(1) {
          width: 149px;
        }
        &:nth-child(2) {
          width: 100px;
        }
        &:nth-child(3) {
          width: 151px;
        }
        &:nth-child(4) {
          flex: 1;
        }
      }
    }
  }
  @import "~@/assets/scss/actual-time.scss";
}
</style>